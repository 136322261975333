export function copyToClipboard(text) {
    let element = document.createElement('textarea');
    element.value = text;
    document.body.appendChild(element);
    element.select();
    navigator.clipboard.writeText(element.value);
    document.body.removeChild(element);
}

export function copyToClipboardByElementId(elementId) {
    const copyText = document.getElementById(elementId);
    copyText.select();
    navigator.clipboard.writeText(copyText.value);
}

window.copyToClipboard = copyToClipboard;
window.copyToClipboardByElementId = copyToClipboardByElementId;