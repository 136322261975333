import {RenderDataTable} from "../components/dataTable";
import {InitDefaultDatePeriodPicker} from "../components/dateFilter";

window.InitializeCrmCampaignResponses = function InitializeAgentPage() {
    InitDefaultDatePeriodPicker();

    $('.js-crmcampaignresponse-preset-select').autoPostBack();

    RenderDataTable('.js-crmcampaignresponse-data-table', {
        'columnDefs': [
            { 'type': 'de_date', 'targets': 0 }
        ],
        'order': [[0, 'desc']]
    });
}