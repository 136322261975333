/**
 * Ставит DatePicker для селекторов .js-datepicker-date-start (начальная дата) и .js-datepicker-date-end (конечная дата)
 * Эти селекторы по умолчанию проставляют при создании пикера через @Html.DatePeriodPickerFor
 */
export function InitDefaultDatePeriodPicker() {
    InitDatePeriodPicker('.js-datepicker-date-start', '.js-datepicker-date-end')
}

/**
 * Ставит DatePicker для селекторов fromSelector (начальная дата) и toSelector (конечная дата)
 */
export function InitDatePeriodPicker(fromSelector, toSelector) {
    var beginDate = $(fromSelector);
    var endDate = $(toSelector);

    var beginDateVal = beginDate.val();
    var endDateVal = endDate.val();

    if (!beginDateVal || !endDateVal) {
        return;
    }

    var beginDateArr = beginDateVal.split('.');
    var endDateArr = endDateVal.split('.');

    let today = new Date();
    let yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

    beginDate.datepicker({
        dateFormat: "dd.mm.yy",
        showOn: 'both',
        buttonText: '',
        changeYear: true,
        changeMonth: true,
        minDate: new Date(2010, 1, 1),
        maxDate: new Date(endDateArr[2], endDateArr[1] - 1, endDateArr[0])
    });
    
    endDate.datepicker({
        dateFormat: "dd.mm.yy",
        showOn: 'both',
        buttonText: '',
        changeYear: true,
        changeMonth: true,
        minDate: new Date(beginDateArr[2], beginDateArr[1] - 1, beginDateArr[0]),
        maxDate: today
    });
    $(".ui-datepicker-trigger").addClass("fa fa-calendar");
    $('#today-button').click(function() {
        beginDate.datepicker("setDate", today);
        endDate.datepicker("setDate", today);
    });

    $('#yesterday-button').click(function() {
        beginDate.datepicker("setDate", yesterday);
        endDate.datepicker("setDate", yesterday);
    });

    $('#this-year-button').click(function() {
        let thisYear = new Date(new Date().getFullYear(), 0, 1);
        beginDate.datepicker("setDate", thisYear);
        endDate.datepicker("setDate", today);
    });

    beginDate.autoPostBack();
    endDate.autoPostBack();
}

window.InitDefaultDatePeriodPicker = InitDefaultDatePeriodPicker;
window.InitDatePeriodPicker = InitDatePeriodPicker;