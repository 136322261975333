import {RenderDataTable} from '../components/dataTable'

window.InitializeContract = function () {
    RenderDataTable('.js-document-contract-data-table', {
        "bSort": false,
        "paging": false,
        "ordering": false,
        "info": false
    });

    $('.activate-contract-button').click(function () {
        var agentTariffTypeId = $(this).data("id");
        $('#AgentTariffTypeId').val(agentTariffTypeId);
        MicroModal.show('activateContractModal');
    });
    
    $('.activate-contract-inner-button').click(function () {
        $('#activation-loader').show();
        
        const formArray = $('#activateContractForm').serializeArray();
        const formObject = {};

        $.map(formArray, function (n, i) {
            formObject[n['name']] = n['value'];
        });

        $.ajax({
            url: window.location.origin + '/Profile/ActivateContractJson',
            type: 'POST',
            contentType: 'application/json;charset=utf-8',
            dataType: 'json',
            data: JSON.stringify(formObject),
            success: function (result) {
                $('#activation-loader').hide();
                MicroModal.close('activateContractModal');
                location.reload();
                $.notify({
                    message: 'Соглашение успешно активировано'
                }, {
                    type: 'success'
                });

            },
            error: function (errormessage) {
                if(errormessage.status === 200){
                    $('#activation-loader').hide();
                    MicroModal.close('activateContractModal');
                    location.reload();
                    $.notify({
                        message: 'Соглашение успешно активировано'
                    }, {
                        type: 'success'
                    });
                }
                else{
                    $('#activation-loader').hide();
                    $.notify({
                        message: errormessage.responseText
                    }, {
                        type: 'danger'
                    });
                }
            }
        });
    });
}