function generateRefreshToken (spaceId, env) {
    $.ajax({
        url: window.location.origin + `/Api/GenerateWhitelabelRefreshToken?spaceId=${spaceId}&env=${env}`,
        type: 'POST',
        contentType: 'application/json;charset=utf-8',
        success: function (result) {
            $('#refreshToken').val(result);
            MicroModal.show('refreshTokenModal');
        },
        error: function (errormessage) {
            alert(errormessage.responseText);
        }
    });
}

window.generatePreProdRefreshToken = function(selectId, spaceId) {
    spaceId = spaceId || getSpaceId(selectId);
    generateRefreshToken(spaceId, "PreProd");
}

window.generateProdRefreshToken = function(selectId, spaceId) {
    spaceId = spaceId || getSpaceId(selectId);
    generateRefreshToken(spaceId, "Prod");
}

function getSpaceId (selectId) {
    let currentSpace = $(selectId)[0];
    return currentSpace.options[currentSpace.selectedIndex].value;
}