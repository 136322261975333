import {RenderDataTable} from '../components/dataTable'

window.InitializeCrmResponses = function () {
  var beginDate = $('.js-crmresponse-date-begin');
  var endDate = $('.js-crmresponse-date-end');

  var beginDateVal = beginDate.val();
  var endDateVal = endDate.val();

  if (!beginDateVal || !endDateVal) {
    return;
  }

  var beginDateArr = beginDateVal.split('.');
  var endDateArr = endDateVal.split('.');

  beginDate.datepicker({
    dateFormat: "dd.mm.yy",
    showOn: 'both',
    buttonText: '',
    changeYear: true,
    changeMonth: true,
      //Минимум, допустимый для выбора в календарике. Раньше этой даты в CRM нет данных, соответственно, пользователь ограничивается в выборе этим минимумом.
    minDate: new Date(2010, 1, 1),
    maxDate: new Date(endDateArr[2], endDateArr[1] - 1, endDateArr[0])
  });

  var yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  endDate.datepicker({
    dateFormat: "dd.mm.yy",
    showOn: 'both',
    buttonText: '',
    changeYear: true,
    changeMonth: true,
    minDate: new Date(beginDateArr[2], beginDateArr[1] - 1, beginDateArr[0]),
    maxDate: yesterday
  });
  
  $(".ui-datepicker-trigger").addClass("fa fa-calendar");

  RenderDataTable('.js-crmresponse-data-table', {
      'columnDefs': [
          { 'type': 'num', 'targets': 4 },
          { 'type': 'string', 'targets': '_all' }
      ]
  });
}