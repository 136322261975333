import {RenderDataTable} from '../components/dataTable'

window.InitializeProfile = function () {
    RenderDataTable('.js-history-points-data-table', {
        "bSort": false,
        "paging": false,
        "ordering": false,
        "info": false
    });

    $('.profile-points-history-modal-link').click(function () {
        MicroModal.show('historyPointsModal');
    });
}