import {RenderDataTable} from '../components/dataTable'

RenderDataTable('.js-document-act-data-table', {
    initComplete: function () {
        this.api().columns([0]).every(function () {
            let column = this;
            let select = $('#filter-date');

            select.on('change', function () {
                column.search($(this).val()).draw();
            });

            column.data()
                .map(function (value) {
                    // take year
                    return value.substr(6);
                })
                .unique()
                .sort().reverse()
                .each(function (value) {
                    select.append('<option value="' + value + '">' + value + '&nbsp;год</option>');
                });
        });
    },
    columnDefs: [
        {type: 'de_date', targets: 0}
    ],
    order: [[0, 'desc']]
});