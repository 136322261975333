import {RenderDataTable} from '../components/dataTable'

window.InitializeClient = function () {
  var beginDate = $('.js-client-date-from');
  var endDate = $('.js-client-date-to');

  var beginDateVal = beginDate.val();
  var endDateVal = endDate.val();

  if (!beginDateVal || !endDateVal) {
    return;
  }

  var beginDateArr = beginDateVal.split('.');
  var endDateArr = endDateVal.split('.');

  beginDate.datepicker({
    dateFormat: "dd.mm.yy",
    showOn: 'both',
    buttonText: '',
    changeYear: true,
    changeMonth: true,
    minDate: new Date(2016, 6, 1),
    maxDate: new Date(endDateArr[2], endDateArr[1] - 1, endDateArr[0])
  });

  var yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  endDate.datepicker({
    dateFormat: "dd.mm.yy",
    showOn: 'both',
    buttonText: '',
    changeYear: true,
    changeMonth: true,
    minDate: new Date(beginDateArr[2], beginDateArr[1] - 1, beginDateArr[0]),
    maxDate: yesterday
  });
  $(".ui-datepicker-trigger").addClass("fa fa-calendar");
  beginDate.autoPostBack();
  endDate.autoPostBack();
  $('#js-client-company-id').autoPostBack();

  RenderDataTable('.js-client-data-table', {
      columnDefs: [
          { type: 'de_date', targets: 1 }
      ],
      order: [[1, 'desc']]
  });
}