import { RenderDataTable } from '../components/dataTable'

window.InitializeCampaignFields = function () {
    let beginDate = $('.js-campaign-date-begin');
    let endDate = $('.js-campaign-date-end');

    beginDate.datepicker({
        dateFormat: 'dd.mm.yy',
        showOn: 'both',
        buttonText: '',
        changeYear: true,
        changeMonth: true,
        minDate: new Date(2016, 6, 1),
        onClose: function(selectedDate) {
            endDate.datepicker('option', 'minDate', selectedDate);
        }
    });

    endDate.datepicker({
        dateFormat: 'dd.mm.yy',
        showOn: 'both',
        buttonText: '',
        changeYear: true,
        changeMonth: true
    });
    $(".ui-datepicker-trigger").addClass("fa fa-calendar");
    RenderDataTable('#js-campaign-sources-data-table');

    $('#campaignForm :input').change(function () {
        $('#campaignForm').data('changed', true);
        $('#btnClearCmp').prop('disabled', false);
    });
}

window.OnCampaignDelete = function (event) {
    event.stopPropagation();
    event.preventDefault();
    let ans = confirm('Вы уверены, что хотите удалить кампанию?');
    if (ans) {
        $.ajax({
            url: event.target.getAttribute ('href'),
            type: 'GET',
            success: function (result) {
                location.reload();
            },
            error: function (error) {
                alert(error.responseText);
            }
        });
    }
}