window.InitializeBannerFields = function InitializeBannerFields() {
    $('.banner-description-textarea').summernote({
            toolbar: [
                    ['style', ['style']],
                    ['font', ['bold', 'underline', 'clear']],
                    ['fontname', ['fontname']],
                    ['fontsize', ['fontsize']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['table', ['table']],
                    ['insert', ['link', 'picture', 'video']],
                    ['view', ['fullscreen', 'codeview', 'help']],
                ],
            placeholder: 'Введите описание',
            tabsize: 2,
            height: 100,
            width: 800,
            lang: 'ru-RU'
    });
}

window.onBannerFileSelected = function onBannerFileSelected(input) {    
    if (input[0].files && input[0].files[0]) {

        checkFileDetails(input[0].files[0]);
    } else {
        $('#previewImg').hide();
    }
}

function checkFileDetails(file) {
    if (!file) {
        return;
    }

    let fileName = file.name;
    let fileExtension = fileName.replace(/^.*\./, '');
    let fileSize = file.size;

    const allowedExtensions = ['png', 'jpg', 'jpeg', 'gif'];

    var html = '<br /> ' +
        'Наименование: <b>' +
        fileName +
        '</b> <br />' +
        'Расширение: <b>' +
        fileExtension +
        '</b> <br />' +
        'Размер: <b>' +
        Math.round((fileSize / 1024)) +
        '</b> KB <br />';

    if (allowedExtensions.indexOf(fileExtension) < 0) {
        html += '<label style="color: #ff0000">Данный файл не поддерживается</label>';
        $('#fileInfo').html(html);
        return;
    }

    let reader = new FileReader();

    reader.onload = function (e) {
        let img = new Image();
        img.src = e.target.result;

        let preview = $('#previewImg');
        preview.attr('src', img.src);
        preview.show();

        img.onload = function () {
            let w = this.width;
            let h = this.height;

            $("#bannerWidth").val(w);
            $("#bannerHeight").val(h);

            html +=
                'Размер в пикселях: <b>' +
                `${w}x${h}` +
                '</b> <br />' +
                'Тип: <b>' +
                file.type +
                '</b> <br />' +
                'Дата загрузки: <b>' +
                file.lastModifiedDate +
                '</b> <br />';

            $('#fileInfo').html(html);
        }
    };
    reader.readAsDataURL(file);
}