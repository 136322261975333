import MicroModal from "micromodal";
import 'array-sort-by';

window.ShowDeleteArticleModal = function () {
    MicroModal.show('delete-article-warning-modal');
}

window.DeleteArticle = function (id) {
    $.ajax({
        url: window.location.origin + '/News/Delete',
        type: 'DELETE',
        data: { "articleId": id },
        success: function (result) {
            window.location.href = window.location.origin + '/News/Index';
        },
        error: function (errormessage) {
            $.notify({ message: errormessage.responseText }, { type: 'danger' });
        }
    });
}

window.InitializeEditArticle = function() {
    InitializeArticleFields();

    $("#tags-input").tagsinput('items');
    
    // prevent form submit on Enter
    $(window).keydown(function(event){
        if(event.keyCode === 13) {
            event.preventDefault();
            return false;
        }
    });
}

function InitializeArticleFields() {
    $('#articleBody').summernote({
        toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline', 'clear']],
            ['fontname', ['fontname']],
            ['fontsize', ['fontsize']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['table', ['table']],
            ['insert', ['link', 'picture', 'video']],
            ['view', ['fullscreen', 'codeview', 'help']],
        ],
        placeholder: 'Введите описание',
        tabsize: 2,
        height: 400,
        lang: 'ru-RU'
    });

    if ($('#publicationDate').val()) {
        $('#removeFromPublishBtn').show();
        $('#saveAndPublishBtn').hide();
        $('#saveArticleBtn').val('Сохранить');
    } else {
        $('#removeFromPublishBtn').hide();
        $('#saveAndPublishBtn').show();
        $('#saveArticleBtn').val('Сохранить черновик');
    }
};

window.onImgPreviewClose = function(closeBtn) {
    var imgWrap = closeBtn[0].parentElement;
    imgWrap.parentElement.removeChild(imgWrap);
    
    $('#TitleImgSrc').val('');
    $('#fileInfo').html('');
    $('#selectedFile').val(null);
};

window.onArticleTitleImgSelected = function (input) {
    if (input[0].files && input[0].files[0]) {

        let file = input[0].files[0];
        if (!file) {
            return;
        }

        let fileName = file.name;
        let fileExtension = fileName.replace(/^.*\./, '');
        let fileSize = file.size;

        const allowedExtensions = ['png', 'jpg', 'jpeg', 'gif'];

        var html = '<br /> ' +
            'Наименование: <b>' +
            fileName +
            '</b> <br />' +
            'Расширение: <b>' +
            fileExtension +
            '</b> <br />' +
            'Размер: <b>' +
            Math.round((fileSize / 1024)) +
            '</b> KB <br />';

        if (allowedExtensions.indexOf(fileExtension.toLowerCase()) < 0) {
            html += '<label style="color: #ff0000">Данный файл не поддерживается</label>';
            $('#fileInfo').html(html);
            return;
        }

        let reader = new FileReader();

        reader.onload = function (e) {
            let img = new Image();
            img.src = e.target.result;

            $('#scaledPreviewImgDiv').html(
                '<div class="article-preview-pic-wrap">' +
                    '<span class="close" onclick="onImgPreviewClose($(this))">&times;</span >' +
                    '<img id="scaledPreviewImg" alt="" src="">' +
                '</div>'
            );

            let preview = $('#scaledPreviewImg');
            preview.attr('src', img.src);
            preview.show();

            img.onload = function () {
                let w = this.width;
                let h = this.height;

                $("#bannerWidth").val(w);
                $("#bannerHeight").val(h);

                html +=
                    'Размер в пикселях: <b>' +
                    `${w}x${h}` +
                    '</b> <br />' +
                    'Тип: <b>' +
                    file.type +
                    '</b> <br />' +
                    'Дата загрузки: <b>' +
                    file.lastModifiedDate +
                    '</b> <br />';

                $('#fileInfo').html(html);
            }
        };
        reader.readAsDataURL(file);
    }
}