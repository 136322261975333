const contentBlocksIds = 
    ['#defaultContent', '#webinarsContent', '#coursesContent', '#productsContent', '#servicesContent'];

window.hideContentExcept = function (srcEl, contentBlockId) {
    $("a.inner-menu__item").removeClass("active");
    $(srcEl).addClass("active");
    contentBlocksIds.forEach(block => {
        $(block).hide();
    })
    $(contentBlockId).show();
}

window.toggleContainer = function (sender, containerName) {
    $(sender).toggleClass('expand-block');
    $(sender).toggleClass('collapse-block');

    $('#' + containerName).animate({ opacity: 'toggle' }, 800);
}