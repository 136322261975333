window.InitializeNotifications = function() {
    $('ul.dropdown-menu').on('click', function(event){
        event.stopPropagation();
    });
}

window.ReadAllAgentNotification = function ReadAllAgentNotification() {
    $.ajax({
        url: window.location.origin + '/Notifications/SetReadAllAgentNotification',
        type: 'POST',
        success: function () {
            $(".fa-envelope").addClass("fa-envelope-open-o");
            $(".fa-envelope-open-o").removeClass("fa-envelope");
        }
    });
};

window.ReadNotification = function ReadNotification(notificationId) {
    $.ajax({
        url: window.location.origin + '/Notifications/SetReadAgentNotification?notificationId=' + notificationId,
        type: 'POST',
        success: function () {
            $("#" + notificationId).removeClass("fa-envelope").addClass("fa-envelope-open-o");
        }
    });
}