import {TrafficSourcesDropdown} from './TrafficSourcesDropdown'

var metricCounters = {
    'finam': 10279138,
    'comon': 1154640
};

window.InitializeWebMetricsYandex = function () {
    var beginDate = $('.js-webmetrics-date-begin');
    var endDate = $('.js-webmetrics-date-end');

    var beginDateVal = beginDate.val();
    var endDateVal = endDate.val();

    if (!beginDateVal || !endDateVal) {
        return;
    }

    var beginDateArr = beginDateVal.split('.');
    var endDateArr = endDateVal.split('.');

    beginDate.datepicker({
        dateFormat: "dd.mm.yy",
        showOn: 'both',
        buttonText: '',
        changeYear: true,
        changeMonth: true,
        minDate: new Date(2016, 6, 1),
        maxDate: new Date(endDateArr[2], endDateArr[1] - 1, endDateArr[0]),
        onClose: function (selectedDate) {
            endDate.datepicker("option", "minDate", selectedDate);
        }
    });

    endDate.datepicker({
        dateFormat: "dd.mm.yy",
        showOn: 'both',
        buttonText: '',
        changeYear: true,
        changeMonth: true,
        minDate: new Date(beginDateArr[2], beginDateArr[1] - 1, beginDateArr[0]),
        maxDate: new Date()
    });
    $(".ui-datepicker-trigger").addClass("fa fa-calendar");
    TrafficSourcesDropdown.onApplyFilter(() => {
        let filter = TrafficSourcesDropdown.getValue();

        $("#form_utmCampaign").val(filter.utmCampaign)
        $("#form_utmContent").val(filter.utmContent)
        $("#form_utmMedium").val(filter.utmMedium)
        $("#form_utmSource").val(filter.utmSource)
        $("#form_utmTerm").val(filter.utmTerm)

        $("#yandex_dashboard_form").submit()
    });

    TrafficSourcesDropdown.onClearFilter(ClearFilter);

    TrafficSourcesDropdown.onChange((newValue) => {

        if (!newValue || !newValue?.ProductLink)
            return;

        let productUrl;
        try {
            productUrl = new URL(newValue.ProductLink);
        } catch {
            return;
        }
        let hostnameArr = productUrl.hostname.split('.');

        let assumedCounterName = hostnameArr[hostnameArr.length - 2];

        let assumedCounterVal = metricCounters[assumedCounterName];

        if (assumedCounterVal) {
            $('#countersDropdown').val(assumedCounterVal);
        }
    })

    TrafficSourcesDropdown.init();

    $('#today-button').click(function () {
        let today = new Date();
        $('.js-webmetrics-date-begin').datepicker("setDate", today);
        $('.js-webmetrics-date-end').datepicker("setDate", today);
    });

    $('#yesterday-button').click(function () {
        var yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
        $('.js-webmetrics-date-begin').datepicker("setDate", yesterday);
        $('.js-webmetrics-date-end').datepicker("setDate", yesterday);
    });

    $('#this-year-button').click(function () {
        var thisYear = new Date(new Date().getFullYear(), 0, 1);
        var today = new Date();
        $('.js-webmetrics-date-begin').datepicker("setDate", thisYear);
        $('.js-webmetrics-date-end').datepicker("setDate", today);
    });

    $(".js-webmetrics-form-loading").hide();

    $(".js-webmetrics-form").submit(function () {
        $(".js-webmetrics-header").hide();
        $(".dataTables_wrapper").hide();
        $(".js-webmetrics-form-loading").show();
    });
}

function ClearFilter() {
    $('#DateFrom').datepicker('setDate', new Date());
    $('#DateTo').datepicker('setDate', new Date());

    $('#countersDropdown').selectedIndex = 0;
}