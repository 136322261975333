var trafficSources = null;
const onCloseCallbacks = [];

export let TrafficSourceCreateModal = {};

TrafficSourceCreateModal.init = function(trafficSource) {

    $('#SourceModalUtmSource').val(trafficSource.utmSource);
    $('#SourceModalUtmMedium').val(trafficSource.utmMedium);
    $('#SourceModalUtmCampaign').val(trafficSource.utmCampaign);
    $('#SourceModalUtmContent').val(trafficSource.utmContent);
    $('#SourceModalUtmTerm').val(trafficSource.utmTerm);

    $("#closeModal").unbind().click(() => {
        MicroModal.close('sourceModal');
    });

    $("#btnAddSrc").unbind().click(addTrafficSource);
    
    MicroModal.show('sourceModal',
        {
            onClose: onClose
        });
}

TrafficSourceCreateModal.onClose = function(callback) {
    onCloseCallbacks.push(callback);
}

function onClose() {
    onCloseCallbacks.forEach((callback) => callback());
}

function addTrafficSource() {
    let form = $('#sourceForm');

    if (!form.valid()) {
        return;
    }

    let srcObj = createSourceObject();

    $.ajax({
        url: window.location.origin + '/TrafficSources/AddOrUpdateJson',
        data: JSON.stringify(srcObj),
        type: 'POST',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json',
        success: function(result) {
            loadSourcesForDropdown();
            MicroModal.close('sourceModal');
        },
        error: function(errormessage) {
            alert(errormessage.responseText);
        }
    });
}

function createSourceObject() {
    let beginDateArr = $('#BeginDate').val().split('.');
    let endDateArr = $('#EndDate').val().split('.');

    let srcObj = {
        TrafficSourceId: $('#TrafficSourceId').val(),
        CampaignId: $('#CampaignId').val(),
        Name: $('#SourceModalName').val(),
        UtmSource: $('#SourceModalUtmSource').val(),
        UtmMedium: $('#SourceModalUtmMedium').val(),
        UtmCampaign: $('#SourceModalUtmCampaign').val(),
        UtmContent: $('#SourceModalUtmContent').val(),
        UtmTerm: $('#SourceModalUtmTerm').val(),
        AdServiceName: $('#AdServiceName').val(),
        ProductLink: $('#ProductLink').val(),
        PlannedCost: $('#PlannedCost').val().replace(',', '.'),
        ActualCost: $('#ActualCost').val().replace(',', '.'),
        BeginDate: new Date(Date.UTC(beginDateArr[2], beginDateArr[1] - 1, beginDateArr[0])),
        EndDate: new Date(Date.UTC(endDateArr[2], endDateArr[1] - 1, endDateArr[0]))
    };

    return srcObj;
}

function saveAsNew() {
    InitializeTrafficSources();

    let utmSource = $('#UtmSource').val();
    let utmMedium = $('#UtmMedium').val();
    let utmCampaign = $('#UtmCampaign').val();
    let utmContent = $('#UtmContent').val();
    let utmTerm = $('#UtmTerm').val();

    $('#SourceModalUtmSource').val(utmSource);
    $('#SourceModalUtmMedium').val(utmMedium);
    $('#SourceModalUtmCampaign').val(utmCampaign);
    $('#SourceModalUtmContent').val(utmContent);
    $('#SourceModalUtmTerm').val(utmTerm);

    MicroModal.show('sourceModal',
        {
            onClose: loadSourcesForDropdown
        });
}


export function loadSourcesForDropdown() {
    $('#sourcesDropdown option').remove();

    let sourcesDropdown = $('#sourcesDropdown');

    sourcesDropdown.append($('<option></option>').text('Выбрать источник').val(''));

    $.ajax({
        url: window.location.origin + '/TrafficSources/ListJson',
        type: 'GET',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json',
        success: function(result) {
            trafficSources = {};

            $.each(result,
                function(key, item)
                {
                    trafficSources[item.TrafficSourceId] = item;
                    sourcesDropdown.append($('<option></option>').text(item.Name).val(item.TrafficSourceId));
                });
        },
        error: function(errormessage) {
            alert(errormessage.responseText);
        }
    });
}

window.loadSourcesForDropdown = loadSourcesForDropdown;