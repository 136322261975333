/**
 * Рисует Jquery DataTable.
 *
 * @options - Можно передать строку с jquery селектором, либо объект типа
 * { $:'.jsSelector', ...остальные параметры DataTable}
 */
export function RenderDataTable(selector, options = null) {
    let dataTableOptions = {
        dom: '<"top">rt<"bottom"lp><"clear">',
        language: {
            lengthMenu: 'Отображать по <select>' +
                '<option value="10">10</option>' +
                '<option value="25">25</option>' +
                '<option value="50">50</option>' +
                '<option value="100">100</option>' +
                '<option value="-1">Все</option>' +
                '</select>',
            paginate: {
                previous: '&larr;',
                next: '&rarr;'
            },
            emptyTable: 'Нет данных.'
        }
    };

    if (options != null){
        // Замещаем дефолтные настройки переданными в аргументе
        dataTableOptions = {...dataTableOptions, ...options};
    }

    $(selector).DataTable(dataTableOptions);
}

window.RenderDataTable = RenderDataTable;