import {RenderDataTable} from '../components/dataTable'

window.InitializeIssues = function () {

    var beginDate = $('.js-issues-date-from');
    var endDate = $('.js-issues-date-to');

    var beginDateVal = beginDate.val();
    var endDateVal = endDate.val();

    if (!beginDateVal || !endDateVal) {
        return;
    }

    var beginDateArr = beginDateVal.split('.');
    var endDateArr = endDateVal.split('.');

    beginDate.datepicker({
        dateFormat: "dd.mm.yy",
        showOn: 'both',
        buttonText: '',
        changeYear: true,
        changeMonth: true,
        minDate: new Date(2016, 6, 1),
        maxDate: new Date(endDateArr[2], endDateArr[1] - 1, endDateArr[0])
    });

    var today = new Date();
    endDate.datepicker({
        dateFormat: "dd.mm.yy",
        showOn: 'both',
        buttonText: '',
        changeYear: true,
        changeMonth: true,
        minDate: new Date(beginDateArr[2], beginDateArr[1] - 1, beginDateArr[0]),
        maxDate: today
    });
    $(".ui-datepicker-trigger").addClass("fa fa-calendar");
    beginDate.autoPostBack();
    endDate.autoPostBack();
    $('#js-issues-issue-type').autoPostBack();

    RenderDataTable('.js-issues-data-table', {
        columnDefs: [
            { type: 'de_date', targets: 0 },
            { type: 'natural', targets: 2}
        ],
        order: [[0, 'desc']]
    });
}

window.InitializeIssueDetails = function () {
    RenderDataTable('.js-issue-history-data-table');
}
