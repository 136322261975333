import {TrafficSourcesDropdown} from './TrafficSourcesDropdown'
import {copyToClipboardByElementId} from '../components/copyToClipboard'

window.InitializeBannerDetailsReadonly = function() {
    TrafficSourcesDropdown.init();
    TrafficSourcesDropdown.onApplyFilter(updateBannerCode);

    $(".copyToClipboard").click((event) => {
        let target = event.target.getAttribute('target');
        copyToClipboardByElementId(target);
    });
}

function updateBannerCode() {
    let targetUrl = $('#targetUrl').text();
    let imgSrc = $('#sourceUrl').val();
    
    let bannerCode = makeBannerCode(targetUrl, imgSrc);
    $('#bannerCodeReferral').val(bannerCode);

    let display = $('#bannerReferralRow').css('display');
    if (display === 'none') {
        $('#bannerReferralRow').animate({ opacity: 'toggle' }, 800);
    }
}

function makeBannerCode(targetUrl, imgSrc) {
    var referralLink = makeReferralLink(targetUrl);
    var result = '<a href="' + referralLink + '"><img src="' + imgSrc + '"></a>';
    return result;
}

function makeReferralLink(targetUrl) {
    var refLink = targetUrl + '?AgencyBackOfficeID=' + $('#agencyId').val() + '&agent=' + $('#userGlobalId').val();

    if ($('#UtmSource').val()) {
        refLink += '&utm_source=' + $('#UtmSource').val();
    }
    if ($('#UtmCampaign').val()) {
        refLink += '&utm_campaign=' + $('#UtmCampaign').val();
    }
    if ($('#UtmMedium').val()) {
        refLink += '&utm_medium=' + $('#UtmMedium').val();
    }
    if ($('#UtmTerm').val()) {
        refLink += '&utm_term=' + $('#UtmTerm').val();
    }
    if ($('#UtmContent').val()) {
        refLink += '&utm_content=' + $('#UtmContent').val();
    }
    
    return refLink;
}
