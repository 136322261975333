﻿window.InitializeOfflineToolFields = function InitializeOfflineToolFields() {
    $('.banner-description-textarea').summernote({
        toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline', 'clear']],
            ['fontname', ['fontname']],
            ['fontsize', ['fontsize']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['table', ['table']],
            ['insert', ['link', 'picture', 'video']],
            ['view', ['fullscreen', 'codeview', 'help']],
        ],
        placeholder: 'Введите описание',
        tabsize: 2,
        height: 100,
        width: 800,
        lang: 'ru-RU'
    });
}

window.OfflineToolOnFileSelected = function OfflineToolOnFileSelected(input, previewSelector, fileInfoSelector) {
    if (input[0].files && input[0].files[0]) {
        checkFileDetails(input[0].files[0], previewSelector, fileInfoSelector);
    } else {
        if (previewSelector) 
            $(previewSelector).hide();
        if (fileInfoSelector)
            $(fileInfoSelector).hide();
    }
}

window.OnOfflineToolDelete = function (event) {
    event.preventDefault();
    let ok = confirm('Вы уверены, что хотите инструмент?');
    if (ok) {
        location.href = event.target.getAttribute('href');
    }
}

function checkFileDetails(file, previewSelector, fileInfoSelector) {
    if (!file) {
        return;
    }

    let fileName = file.name;
    let fileExtension = fileName.replace(/^.*\./, '');
    let fileSize = file.size;

    let html = '<br /> ' +
        'Наименование: <b>' +
        fileName +
        '</b> <br />' +
        'Расширение: <b>' +
        fileExtension +
        '</b> <br />' +
        'Размер: <b>' +
        Math.round((fileSize / 1024)) +
        '</b> KB <br />' +
        'Дата загрузки: <b>' +
        file.lastModifiedDate +
        '</b> <br />';

    $(fileInfoSelector).html(html);
    $(fileInfoSelector).show();
    
    let reader = new FileReader();
    reader.onload = function (e) {
        let img = new Image();
        img.src = e.target.result;
        
        if (previewSelector) {
            let preview = $(previewSelector);
            preview.attr('src', img.src);
            preview.show();
            
            if (fileInfoSelector) {
                img.onload = function () {
                    let w = this.width;
                    let h = this.height;

                    html +=
                        'Размер в пикселях: <b>' +
                        `${w}x${h}` +
                        '</b> <br />' +
                        'Тип: <b>' +
                        file.type +
                        '</b> <br />';

                    $(fileInfoSelector).html(html);
                }
            }
        }
    };

    reader.readAsDataURL(file);
}