import {RenderDataTable} from '../components/dataTable'

window.InitializeWebMetrics = function () {
  var beginDate = $('.js-webmetrics-date-begin');
  var endDate = $('.js-webmetrics-date-end');

  var beginDateVal = beginDate.val();
  var endDateVal = endDate.val();

  if (!beginDateVal || !endDateVal) {
    return;
  }

  var beginDateArr = beginDateVal.split('.');
  var endDateArr = endDateVal.split('.');

  beginDate.datepicker({
    dateFormat: "dd.mm.yy",
    showOn: 'both',
    buttonText: '',
    changeYear: true,
    changeMonth: true,
    minDate: new Date(2016, 6, 1),
    maxDate: new Date(endDateArr[2], endDateArr[1] - 1, endDateArr[0]),
    onClose: function (selectedDate) {
        endDate.datepicker("option", "minDate", selectedDate);
    }
  });

  endDate.datepicker({
    dateFormat: "dd.mm.yy",
    showOn: 'both',
    buttonText: '',
    changeYear: true,
    changeMonth: true,
    minDate: new Date(beginDateArr[2], beginDateArr[1] - 1, beginDateArr[0]),
    maxDate: new Date()
  });
  $(".ui-datepicker-trigger").addClass("fa fa-calendar");
  $(".js-webmetrics-form-loading").hide();
  
  $(".js-webmetrics-form").submit(function () {
    $(".js-webmetrics-header").hide();
    $(".dataTables_wrapper").hide();
    $(".js-webmetrics-form-loading").show();
  });

    loadSourcesForDropdown();
  
  RenderDataTable('.js-webmetrics-by-sites-data-table', {
    order: [[3, 'desc']]
  });

  RenderDataTable('.js-webmetrics-by-landing-data-table', {
    columnDefs: [
        { "orderable": false, "targets": 2 }
    ],
    order: [[4, 'desc']]
  });

  var mouseLeaveTimer;
  var tooltipCloseTimeout = 2000;
  $('.js-webmetrics-tooltip').tooltip({
    open: function () {
      // make sure all other tooltips are closed when opening a new one
      $('.js-webmetrics-tooltip').not(this).tooltip('close');
    }
  }).on('mouseleave', function(e) {
    var that = this;

    // close the tooltip later (maybe ...)
    mouseLeaveTimer = setTimeout(function() {
      $(that).tooltip('close');
    }, tooltipCloseTimeout);

    // prevent tooltip widget to close the tooltip now
    e.stopImmediatePropagation(); 
  });

  $(document).on('mouseenter', '.ui-tooltip', function(e){
    // cancel tooltip closing on hover
    clearTimeout(mouseLeaveTimer);
  });

  $(document).on('mouseleave', '.ui-tooltip', function() {
    // make sure tooltip is closed when the mouse is gone
    $('.js-webmetrics-tooltip').tooltip('close');
  });

}
