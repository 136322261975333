import {TrafficSourcesDropdown} from './TrafficSourcesDropdown'

window.InitializeReferral = function(isNotAgent) {
    if (isNotAgent !== "true")
    {
        let refLink = $("#referralDirect");

        if (refLink.val() !== ''){
            refLink[0].scrollIntoView();
        }

        TrafficSourcesDropdown.init();

        TrafficSourcesDropdown.onApplyFilter(() => {
            let filter = TrafficSourcesDropdown.getValue();

            $("#form_utmCampaign").val(filter.utmCampaign)
            $("#form_utmContent").val(filter.utmContent)
            $("#form_utmMedium").val(filter.utmMedium)
            $("#form_utmSource").val(filter.utmSource)
            $("#form_utmTerm").val(filter.utmTerm)

            $("#referral_validation_form").submit()
        });
    }

    $('#addShortLink').click(function () {

        if (!$('#shortLink').val()) {
            $.notify({ message: "Заполните поле для формирования короткой ссылки" }, { type: 'danger' });
            return;
        }

        $.ajax({
            url: window.location.origin + '/ShortLink/Add',
            type: 'POST',
            data: { "url": $('#shortLink').val()},
            success: function (result) {
                $('#shortLink').val(result);
                $('#copyShortLink').click(function () {
                    copyToClipboard(result);
                });
            },
            error: function (errormessage) {
                $.notify({ message: errormessage.responseText }, { type: 'danger' });
            }
        });
    });
}

window.toggleUtmTags = function (isNotAgent) {
    if (isNotAgent !== "true")
    {
        var utmTagsBlock = $("#utmTags");
        var toggleicon = $("#utmTags-toggle-icon");
        
        if (utmTagsBlock.hasClass("hidden"))
        {
            utmTagsBlock.removeClass("hidden");
            toggleicon.removeClass("fa-plus");
            toggleicon.addClass("fa-minus");
        }
        else
        {
            utmTagsBlock.addClass("hidden");
            toggleicon.addClass("fa-plus");
            toggleicon.removeClass("fa-minus");
        }
    }
}