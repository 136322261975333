import {RenderDataTable} from '../components/dataTable'

window.InitializeAgentPage = function InitializeAgentPage() {
  var beginDate = $('.js-agent-date-begin');
  var endDate = $('.js-agent-date-end');

  var beginDateVal = beginDate.val();
  var endDateVal = endDate.val();

  if (!beginDateVal || !endDateVal) {
    return;
  }

  beginDate.datepicker({
    dateFormat: "dd.mm.yy",
    showOn: 'both',
    buttonText: '',
    changeYear: true,
    changeMonth: true,
    minDate: new Date(2016, 6, 1),
    maxDate: new Date()
  });

  var yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  endDate.datepicker({
    dateFormat: "dd.mm.yy",
    showOn: 'both',
    buttonText: '',
    changeYear: true,
    changeMonth: true,
    maxDate: yesterday
  });

  $(".ui-datepicker-trigger").addClass("fa fa-calendar");
  
  RenderDataTable('.js-agent-data-table', {
      columnDefs: [
          { type: 'de_date', targets: 1 },
          { type: 'currency', targets: [2, 3, 4, 5, 6, 7, 8, 9 ]}
      ],
      order: [[1, 'desc']]
  });

  $('.js-webmetrics-form-loading').hide();
  $('#agent_contracts_partial').show();
  $('#agent_contracts_form').submit(function () {
      $('#agent_contracts_partial').hide();
      $('.js-webmetrics-form-loading').show();
  });
}

