$.validator.methods.number = function (value, element) {
    return this.optional(element) || /^-?(?:\d+|\d{1,3}(?:\.\d{3})+)?(?:,\d+)?$/.test(value);
}

window.InitializeTrafficSources = function () {
    let beginDate = $('.js-source-date-begin');
    let endDate = $('.js-source-date-end');

    beginDate.datepicker({
        dateFormat: 'dd.mm.yy',
        showOn: 'both',
        buttonText: '',
        changeYear: true,
        changeMonth: true,
        minDate: new Date(2016, 6, 1),
        onClose: function(selectedDate) {
            endDate.datepicker('option', 'minDate', selectedDate);
        }
    });

    endDate.datepicker({
        dateFormat: 'dd.mm.yy',
        showOn: 'both',
        buttonText: '',
        changeYear: true,
        changeMonth: true
    });

    $(".ui-datepicker-trigger").addClass("fa fa-calendar");
}


window.OnTrafficSourceDelete = function (event) {
    event.stopPropagation();
    event.preventDefault();
    let ans = confirm('Вы уверены, что хотите удалить источник?');
    if (ans) {
        $.ajax({
            url: event.target.getAttribute ('href'),
            type: 'GET',
            success: function (result) {
                location.reload();
            },
            error: function (error) {
                alert(error.responseText);
            }
        });
    }
}