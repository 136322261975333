window.InitializeAttractedClients = function () {
    
    const microModalConfig = {
        onClose: modal => {
            location.reload();
        }
    };
    $('.add-client-button').click(function () {
        MicroModal.show('addPersonModal', microModalConfig);
    });
    
    $('.edit-client-button').click(function () {
        const id = $(this).data('id');
        $.ajax({
            url: window.location.origin + '/AttractedClients/GetPerson/'+id,
            type: 'GET',
            contentType: 'application/json;charset=utf-8',
            dataType: 'json',
            success: function(result) {
                const form = $('#personForm'); 
                populate(form, result);
                form.attr('action', '/AttractedClients/EditPerson');
                form.attr('method', 'post');
                $('.add-another-button').hide();
                $('.header-label').text('Редактирование карточки клиента');
                
                MicroModal.show('addPersonModal', microModalConfig);
            },
            error: function(errormessage) {
                alert(errormessage.responseText);
            }
        }); 
    });
    
    $('.send-clients-button').click(function () {
        $.ajax({
            url: $(this).data('url'),
            type: 'POST',
            success: function(result) {
                MicroModal.show('sendClientsModal', microModalConfig)
            },
            error: function(errormessage) {
                alert(errormessage.responseText);
            }
        });
    });
    
    $('.add-another-button').click(function () {
        const formArray = $('#personForm').serializeArray();
        const formObject = {};

        $.map(formArray, function(n, i){
            formObject[n['name']] = n['value'];
        });

        $.ajax({
            url: window.location.origin + '/AttractedClients/AddPersonJson',
            type: 'POST',
            contentType: 'application/json;charset=utf-8',
            dataType: 'json',
            data: JSON.stringify(formObject),
            success: function(result) {
                $('#personForm').trigger('reset');
                $.notify({
                    message: `Клиент ${result.LastName + ' ' + result.FirstName} успешно добавлен`
                },{
                    type: 'success'
                });
            },
            error: function(errormessage) {
                alert(errormessage.responseText);
            }
        });
    });
    
    $('#PhoneNumber').inputmask('+ 9 (999) 999-99-99');
    $('#Email').inputmask('email');
    
    $('#PhoneNumber').rules('add', {
        required: '#Email:blank'
    });

    $('#Email').rules('add', {
        required: '#PhoneNumber:blank'
    });

    function populate(form, data) {
        $.each(data, function(key, value) {
            let ctrl = $('[name='+key+']', form);
            switch(ctrl.prop("type")) {
                case "radio": case "checkbox":
                    ctrl.each(function() {
                        if($(this).attr('value') == value) $(this).attr("checked",value);
                    });
                    break;
                default:
                    ctrl.val(value);
            }
        });
    }
}