window.InitializeBannersList = function () {

    const formArray = $('#bannersForm').serializeArray();

    let initState = {
        "Filter_Tags": $("#Filter_Tags").val()
    };

    $.map(formArray, function (el) {
        initState[el['name']] = el['value'];
    });

    const setInitState = () => {
        for (let inputName in initState) {
            const inputId = inputName.replace(".", "_");
            $(`#${inputId}`).val(initState[inputName]);
        }
    }

    const nextPage = (pageNumber) => {
        const currentPageNumber = $("#Filter_PageNumber").val();
        $("#Filter_PageNumber").val(+currentPageNumber + pageNumber);
        $('#bannersForm').submit();
    }
    
    const changePage = (pageNumber) => {
        $("#Filter_PageNumber").val(pageNumber);
        $('#bannersForm').submit();
    }

    $('.paging-input').click((e) => {
        setInitState();
        changePage(+e.target.id);
    });
    
    $('.prev-button').click(() => {
        setInitState();
        nextPage(-1);
    });

    $('.next-button').click(() => {
        setInitState();
        nextPage(1);
    });

    $('.select-button').click(() => $('#bannersForm').submit());
    $('#bannersForm :input').change(() => $("#Filter_PageNumber").val(1));
}