import { TrafficSourceCreateModal } from "./TrafficSourceCreateModal";

var trafficSources = null;

export let TrafficSourcesDropdown = {};

TrafficSourcesDropdown.init = function() {
    loadSourcesForDropdown();
    TrafficSourcesDropdown.onClearFilter(clearUtmTags);
    TrafficSourcesDropdown.onChange(onSourceChanged);
}

TrafficSourcesDropdown.onApplyFilter = function (callback) {
    $("#applyTrafficSourceFilter").click(callback);
}

TrafficSourcesDropdown.onClearFilter = function (callback) {
    $("#clearUtmTags").click(callback);
}

TrafficSourcesDropdown.onChange = function (callback) {
    $("#sourcesDropdown").click(() => {

        let sourceId = $('#sourcesDropdown').val();
        let source = trafficSources[sourceId];
        
        callback(source);
    });
}

TrafficSourcesDropdown.getValue = function() {
    return {
        utmSource : $('#UtmSource').val(),
        utmMedium : $('#UtmMedium').val(),
        utmCampaign : $('#UtmCampaign').val(),
        utmContent : $('#UtmContent').val(),
        utmTerm : $('#UtmTerm').val()
    }
}

function onSourceChanged(newValue) {
    if (!newValue) {
        return;
    }

    $('#UtmSource').val(newValue.UtmSource);
    $('#UtmMedium').val(newValue.UtmMedium);
    $('#UtmContent').val(newValue.UtmContent);
    $('#UtmTerm').val(newValue.UtmTerm);
    $('#UtmCampaign').val(newValue.UtmCampaign);
    $('#DateFrom').datepicker('setDate', new Date(Date.parse(newValue.BeginDate)));
    $('#DateTo').datepicker('setDate', new Date(Date.parse(newValue.EndDate)));
}

window.SaveAsNewTrafficSource = function () {
    let newTrafficSource = TrafficSourcesDropdown.getValue();

    // Показываем modal добавления нового источника
    TrafficSourceCreateModal.init(newTrafficSource);
    TrafficSourceCreateModal.onClose(loadSourcesForDropdown);
}

function clearUtmTags() {
    $('#UtmSource').val('');
    $('#UtmMedium').val('');
    $('#UtmCampaign').val('');
    $('#UtmContent').val('');
    $('#UtmTerm').val('');
    $('#sourcesDropdown').val('');
}

function loadSourcesForDropdown() {
    $('#sourcesDropdown option').remove();

    let sourcesDropdown = $('#sourcesDropdown');

    sourcesDropdown.append($('<option></option>').text('Выбрать источник').val(''));

    $.ajax({
        url: window.location.origin + '/TrafficSources/ListJson',
        type: 'GET',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json',
        success: function(result) {
            trafficSources = {};

            $.each(result,
                function(key, item)
                {
                    trafficSources[item.TrafficSourceId] = item;
                    sourcesDropdown.append($('<option></option>').text(item.Name).val(item.TrafficSourceId));
                });
        },
        error: function(errormessage) {
            alert(errormessage.responseText);
        }
    });
}